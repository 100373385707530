import './LandingCreateProfile.css'
import { HSpacer } from "../../../comp/layout/HSpacer";
import { LandingSection } from "../../../comp/layout/LandingSection";
import { ResponsiveRow } from "../../../comp/layout/ResponsiveRow";
import NPTextInput from "../../../forms/NPTextInput";
import { NPPhone } from '../../../forms/NPPhone';
import NPPriceInput from '../../../forms/NPPriceInput';
import { NPCheckBox } from '../../../comp/NPCheckBox';
import { useEffect, useState } from 'react';
import NPButton from '../../../comp/NPButton';
import { AdBox } from './AdBox';
import { NPRadioButtons } from '../../../forms/NPRadioButtons';
import { NPValidationError } from '../../../forms/NPValidationError';
import MarketplaceAPI from '../../../api/MarketplaceAPI';
import AffareTrattoreAPI from '../../../api/AffareTrattoreAPI';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { NPLink } from '../../../comp/NPLink';
import AuthAPI from '../../../api/AuthAPI';
import { storeUser } from '../../../util/UserUtil';
import { USER_ACTIVITIES, UserActivityLoggerInst } from '../../../util/UserEventLogger';
import { Obfuscator } from '../../../util/Obfuscator';

const userTypeOptions = [
    { code: "private", label: "Privato" },
    { code: "company", label: "Azienda" }
]

const mktSellerOptions = [
    { code: "seller", label: "Si, sono un inserzionista su Affare Trattore" },
    { code: "generic", label: "No" }
]

const links = [
    { label: "Termini di Utilizzo", link: "https://pleggit.com/terms" },
    { label: "Policy sulla Privacy", link: "https://pleggit.com/privacy" },
]

/**
 * 
 * @param noAd Optional. Pass true to avoid displaying the ad.
 * @param enableMarketplaceSeller Optional. Pass true to give the possibility to register as a Marketplace seller.
 * 
 * @returns 
 */
export function LandingCreateProfile({ onCompleted, noAd, enableMarketplaceSeller }) {

    const [idAd, setIdAd] = useState()
    const [email, setEmail] = useState()
    const [pswd, setPswd] = useState()
    const [confirmPswd, setConfirmPswd] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [cf, setCf] = useState()
    const [legalName, setLegalName] = useState()
    const [piva, setPiva] = useState()
    const [userType, setUserType] = useState(enableMarketplaceSeller ? userTypeOptions[1] : userTypeOptions[0])
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [phoneCountryCode, setPhoneCountryCode] = useState()
    const [phone, setPhone] = useState()
    const [validationError, setValidationError] = useState()
    const [licensePlates, setLicensePlates] = useState()
    const [price, setPrice] = useState()
    const [adTitle, setAdTitle] = useState()
    const [adImageURL, setAdImageURL] = useState()
    const [marketplaceSellerId, setMarketplaceSellerId] = useState()
    const [marketplaceSellerFlag, setMarketplaceSellerFlag] = useState(mktSellerOptions[0])
    const [marketplaceUserId, setMarketplaceUserId] = useState();       // This field tracks the user id that the seller has on Affare Trattore
    const [returningCustomer, setReturningCustomer] = useState(false);

    const [registrationOngoing, setRegistrationOngoing] = useState(false)
    const [loadingAd, setLoadingAd] = useState()

    const location = useLocation()
    const history = useHistory()

    /**
     * This logs user activity, to get some analytics on what the user is doing on this page
     */
    useEffect(() => {

        const obfuscator = new Obfuscator()

        const data = {
            email: obfuscator.hideEmail(email),
            privacyTermsFlag: termsAccepted,
            userType: userType.code,
            price: price,
            adId: idAd,
            adTitle: adTitle,
            adImageURL: adImageURL,
            marketplaceSellerId: marketplaceSellerId,
            marketplaceSellerFlag: enableMarketplaceSeller && marketplaceSellerFlag ? marketplaceSellerFlag.code == "seller" : false,
            marketplaceUserId: marketplaceUserId
        }

        UserActivityLoggerInst.logActivity(USER_ACTIVITIES.fieldFilled, data)

    }, [email, pswd, confirmPswd, firstName, lastName, cf, legalName, piva, termsAccepted, phone, phoneCountryCode, validationError, licensePlates, price, adTitle, adImageURL, marketplaceSellerId, marketplaceSellerFlag, marketplaceUserId, returningCustomer])

    /**
     * Load the Marketplace Ad
     */
    const loadAdData = async () => {

        const idAd = new URLSearchParams(location.search).get('id')

        if (!idAd) return;

        setIdAd(idAd)

        setLoadingAd(true)

        const adDetails = await new AffareTrattoreAPI().getAdDetails(idAd);

        setLoadingAd(false)

        if (adDetails) {
            setAdTitle(adDetails.title)
            setPrice(adDetails.price)
            setAdImageURL(new AffareTrattoreAPI().getAdImageProxiedUrl(adDetails.image))
            setMarketplaceSellerId(adDetails.user_id)
        }

    }

    const clearForm = () => {
        setMarketplaceUserId(null)
    }

    /**
     * User has clicked on "continue". 
     * 
     * This function manages two scenarios: 
     * 
     * 1. The user is NOT a Returning Customer, in which case the function will start the registration process
     * 
     * 2. The user IS a Returning Customer, in which case the function will log in, register the new transaction and get the user in the app 
     * when the new transaction has been created.
     * 
     */
    const onProceed = async () => {

        setRegistrationOngoing(true)

        const validationResult = validate()

        // Show error message
        if (!validationResult.valid) {
            setRegistrationOngoing(false)
            setValidationError(validationResult.message)
            return;
        }

        // Based on whether the user is a Returning Customer or not, register the user or proceed with Login and transaction registration
        if (!returningCustomer) {
            // Send the info to the Marketplace API
            const registrationResult = await new MarketplaceAPI().postRegistration({
                email: email,
                password: pswd,
                privacyTermsFlag: termsAccepted,
                userType: userType.code,
                price: price,
                adId: idAd,
                adTitle: adTitle,
                adImageURL: adImageURL,
                marketplaceSellerId: marketplaceSellerId,
                marketplaceSellerFlag: enableMarketplaceSeller && marketplaceSellerFlag ? marketplaceSellerFlag.code == "seller" : false,
                marketplaceUserId: marketplaceUserId
            })

            // Handle any backend validation error or other errors
            if (registrationResult && registrationResult.code == 400) {

                if (registrationResult.userAlreadyExists) {
                    showValidationError("L'indirizzo email risulta già registrato.")
                    return;
                }

                showValidationError(`Si è verificato un errore: ${registrationResult.msg}`)
                return;
            }
        }
        else {
            // 1. Login
            const result = await new AuthAPI().signIn(email, pswd);

            if (!result) {
                showValidationError("Si è verificato un errore nella login. Per favore riprova più tardi.")
                return;
            }

            if (result.userExists === false) {
                showValidationError("L'utente non è stato trovato")
                return;
            }

            if (result.loginSucceeded === false) {
                showValidationError("Username o password errate")
                return;
            }

            console.log("[login] - User is authorized to access!");

            storeUser(result, "pleggit");

            // 2. Register new transaction
            const newTransactionResult = await new MarketplaceAPI().postNewTransaction({
                price: price,
                adId: idAd,
                adTitle: adTitle,
                adImageURL: adImageURL,
                marketplaceSellerId: marketplaceSellerId,
                marketplaceSellerFlag: false,
            })

            // Handle any backend validation error or other errors
            if (newTransactionResult && newTransactionResult.code == 400) {

                showValidationError(`Si è verificato un errore: ${newTransactionResult.msg}`)
                return;
            }

            // 3. Go to the dashboard
            history.push('/dashboard');
        }

        setRegistrationOngoing(false)

        if (onCompleted) onCompleted();

    }

    /**
     * Shows a validation error
     */
    const showValidationError = (message) => {
        setValidationError(message)
        setRegistrationOngoing(false)
    }

    /**
     * Validate the user input
    */
    const validate = () => {

        setValidationError(null)

        // 0. Marketplace Seller Flag
        if (enableMarketplaceSeller && marketplaceSellerFlag && marketplaceSellerFlag.code == 'seller') {
            if (!marketplaceUserId) return { valid: false, message: <>E' necessario inserire il tuo ID utente di Affare Trattore per proseguire.<br />Questo dato ci aiuta a collegare possibili compratori ai tuoi annunci.</> }
        }

        // 1. Mandatory fields
        if (!email) return { valid: false, message: "Per favore inserisci una email valida." }
        if (!pswd) return { valid: false, message: "Per favore inserisci una password." }

        // 1.1 Mandatory Fields when registering
        if (!returningCustomer) {
            if (!confirmPswd) return { valid: false, message: "Per favore conferma la password." }
            if (!termsAccepted) return { valid: false, message: "Per favore prendi visione e accetta i Terms & Conditions di Pleggit prima di proseguire." }
        }

        // 2. User-type specific validation

        // 3. Mail syntax
        if (email.indexOf("@") <= 0) return { valid: false, message: "Per favore inserisci una email valida." }
        if (email.indexOf("@") == email.length - 1) return { valid: false, message: "Per favore inserisci una email valida." }
        if (email.indexOf(".") <= 0) return { valid: false, message: "Per favore inserisci una email valida." }
        if (email.lastIndexOf(".") == email.length - 1) return { valid: false, message: "Per favore inserisci una email valida." }
        if (email.lastIndexOf(".") < email.indexOf("@")) return { valid: false, message: "Per favore inserisci una email valida." }

        // 4. Passwords must match
        if (!returningCustomer) {
            if (pswd != confirmPswd) return { valid: false, message: "Le due password non coincidono." }
        }

        return { valid: true }
    }

    useEffect(() => { loadAdData() }, [])

    return (
        <LandingSection className="landing-create-profile">
            <div className="title">Crea il tuo Profilo</div>
            <HSpacer size="large" />
            <ResponsiveRow align="flex-start">
                <div className="form">
                    {validationError &&
                        <>
                            <NPValidationError errorMessage={validationError} />
                            <HSpacer size="large" />
                        </>
                    }

                    {/* <NPLink text="Sei già registrato? Effettua la login." href={`/?id=${idAd}`} /> */}
                    <div style={{ display: 'flex' }}>
                        <NPCheckBox label="Sono già registrato" onClick={() => { setReturningCustomer(!returningCustomer) }} ticked={returningCustomer} />
                    </div>
                    <HSpacer size="large" />

                    {enableMarketplaceSeller &&
                        <>
                            <NPRadioButtons row={true} label="Sei un Venditore (inserzionista) su Affare Trattore?" options={mktSellerOptions} selectedOption={marketplaceSellerFlag} onSelectOption={(o) => setMarketplaceSellerFlag(o)} />
                            <HSpacer />
                            {marketplaceSellerFlag && marketplaceSellerFlag.code == "seller" &&
                                <>
                                    <NPTextInput label="Id utente su Affare Trattore" mandatory={true} onChange={(v) => { setMarketplaceUserId(v) }} info={<div>Puoi trovare questo identificativo <b>sulla piattaforma di Affare Trattore</b>, nella sezione dedicata al tuo Profilo di inserzionista.<br />Il campo da cercare si chiama "ID Utente".</div>} />
                                    <HSpacer size="large" />
                                </>
                            }
                        </>
                    }

                    {!returningCustomer &&
                        <>
                            <NPTextInput label="Indirizzo email" mandatory={true} onChange={(v) => { setEmail(v) }} />
                            <NPTextInput label="Crea una Password" type="password" mandatory={true} onChange={(v) => { setPswd(v) }} />
                            <NPTextInput label="Conferma la Password" type="password" mandatory={true} onChange={(v) => { setConfirmPswd(v) }} />
                        </>
                    }

                    {returningCustomer &&
                        <>
                            <NPTextInput label="Indirizzo email" mandatory={true} onChange={(v) => { setEmail(v) }} />
                            <NPTextInput label="Password" type="password" mandatory={true} onChange={(v) => { setPswd(v) }} />
                        </>
                    }

                    <HSpacer size="large" />

                    {!returningCustomer &&
                        <NPCheckBox ticked={termsAccepted} label="Accetto i Termini di Utilizzo di Pleggit e al trattamento dei Dati Personali riportati qui sotto" links={links} onClick={() => { setTermsAccepted(!termsAccepted) }} />
                    }
                </div>
                {!noAd &&
                    <div className='ad-container'>
                        <AdBox loading={loadingAd} title={adTitle} imageUrl={adImageURL} price={price} />
                    </div>
                }
            </ResponsiveRow>
            <HSpacer size="x-large" />
            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                <NPButton label="Prosegui" onClick={onProceed} disabled={registrationOngoing} />
            </div>
        </LandingSection>
    )
}