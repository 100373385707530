import './NegotiationWidget.css'
import NPCard from "../../../comp/card/NPCard";
import { NPCardMessage } from "../../../comp/card/NPCardMessage";
import { useEffect, useState } from 'react';
import { Conversation } from './parts/Conversation';
import MarketplaceAPI from '../../../api/MarketplaceAPI';

export function NegotiationWidget({ transaction }) {

    const [interested, setInterested] = useState(false)
    const [showChat, setShowChat] = useState(true)

    const firstMessage = "<span><p>Buongiorno.</p><p>Sono interessato a questo bene.</p><p>Vorrei chiedere la <b>conferma del prezzo</b> (<b>IVA inclusa</b>) e eventuali <b>costi di spedizione</b> (se a mio carico).</p></span>"

    /**
     * Starts the negotiation
     */
    const onStartNegotiation = async () => {

        await new MarketplaceAPI().postInterest(transaction.id, firstMessage);

        setInterested(true)

        setTimeout(() => { setShowChat(true) }, 500);

    }

    useEffect(() => {

    }, [interested])

    return (
        <div className="negotiation-widget">
            <NPCard>

                {/* <div className="card-title">
                    La tua trattativa in corso
                </div>
                <div className='core-info'>
                    <NPLabeledValue label="Prezzo attuale" value={transaction.price} type="currency" notAvailableLabel="Non disponibile" />
                    <Spacer />
                    <NPLabeledValue label="Costo di spedizione" value={transaction.shippingCost} type="currency" notAvailableLabel="Non disponibile" />
                </div> */}

                {!interested &&
                    <NPCardMessage
                        text={<div>Sei interessato a comprare questo bene? <span>Notifica il venditore e richiedi conferma del prezzo.</span></div>}
                        actionTitle="Sono interessato"
                        onAction={onStartNegotiation}
                        instructions={<>Questa azione <b>non è vincolante</b>. Puoi sempre decidere di non proseguire con la transazione in qualsiasi momento.</>}
                    />
                }
                {interested &&
                    <NPCardMessage
                        text={<>Abbiamo notificato il Venditore del tuo interesse. Riceverai una mail appena avrà risposto.</>}
                        instructions={<>Nella sezione qui sotto ("La tua trattativa") potrai controllare lo stato della richiesta.</>}
                        icon="ok"
                        noBorder={true}
                    />
                }
            </NPCard>
            <div className={`conversation-area show-${showChat}`}>
                <NPCard>
                    <Conversation transaction={transaction} />
                </NPCard>
            </div>
        </div>
    )

}