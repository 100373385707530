import { HSpacer } from '../../../../comp/layout/HSpacer';
import './Conversation.css'
import { NegotiationMessage } from "./NegotiationMessage";
import { ReactComponent as PleggitLogoSVG } from '../../../../images/logo/logo-no-color.svg'
import MarketplaceAPI from '../../../../api/MarketplaceAPI';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';

const cookies = new Cookies()

export function Conversation({ transaction }) {

    const firstMessage = (<span><p>Buongiorno.</p><p>Sono interessato a questo bene.</p><p>Vorrei chiedere la <b>conferma del prezzo</b> (<b>IVA inclusa</b>) e eventuali <b>costi di spedizione</b> (se a mio carico).</p></span>)

    const [messages, setMessages] = useState([])

    const loggedUser = cookies.get('user');

    /**
     * Loads the converastion for the given transaction
     */
    const loadConversation = async () => {

        let partyType = 'buyer'
        if (loggedUser.userId == transaction.sellerUserId) partyType = 'seller'

        const result = await new MarketplaceAPI().getConversation(transaction.id, partyType);

        setMessages(result.conversation);

        console.log(result.conversation);


    }

    useEffect(() => { loadConversation() }, [])

    return (
        <div className='conversation'>
            <div className="conversation-title">
                <div className='logo'><PleggitLogoSVG /></div>
                La tua trattativa
            </div>
            {messages.map((msg) => {
                return (
                    <>
                        <NegotiationMessage
                            text={msg.msg}
                            me={msg.uid == loggedUser.userId}
                        />
                        <HSpacer />
                    </>
                )
            })}

        </div>
    )

}