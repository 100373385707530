import './NegotiationMessage.css'

export function NegotiationMessage({ text, datetime, me }) {

    return (
        <div className={`negotiation-message me-${me}`}>
            <div className="icon-container">
            </div>
            <div className="message">
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div>
    )

}